import tinymce from "tinymce/tinymce";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this.defaults = {
      base_url: "/tinymce", // base_url을 수정
      plugins: [
        "advlist",
        "autolink",
        "lists",
        "link",
        "image",
        "charmap",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "help",
        "wordcount",
      ].join(" "),
      toolbar: [
        "undo redo | blocks | bold italic | alignleft aligncenter alignright |",
        "bullist numlist outdent indent | image media link | removeformat | help",
      ].join(" "),
      toolbar_sticky: true,
      images_upload_url: "/tinymce_uploader/image",
      file_picker_types: "file image media",
      relative_urls: false,
      language_url: "/tinymce/langs/ko_KR.js",
      language: "ko_KR",
      content_style: "body { font-size:16px }",
      promotion: false,
      branding: false,
    };
  }

  connect() {
    // Initialize the app
    let config = Object.assign({ target: this.inputTarget }, this.defaults);
    tinymce.init(config).then();
  }

  disconnect() {
    tinymce.remove();
  }
}
