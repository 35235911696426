import consumer from "./consumer"

const chatChannel = consumer.subscriptions.create("ChatChannel", {
  connected() {
    console.log("Connected to the chat channel")
  },

  disconnected() {
    console.log("Disconnected from the chat channel")
  },

  received(data) {
    console.log("Received message:", data.message)
    // 여기에서 받은 메시지를 화면에 표시하는 로직을 구현합니다
    if (this.messageContainer) {
      const messageElement = document.createElement('div')
      messageElement.textContent = data.message
      this.messageContainer.appendChild(messageElement)
    }
  }
});

// 메시지를 서버로 전송하는 함수
window.sendMessage = function(message) {
  chatChannel.perform('receive', { message: message })
}

// 메시지 컨테이너 설정
document.addEventListener('turbo:load', () => {
  chatChannel.messageContainer = document.getElementById('chat-messages')
})
