// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable";

// 환경에 따라 적절한 WebSocket URL 설정
const wsUrl =
  process.env.NODE_ENV === "production"
    ? "wss://ewhamd.kr/cable"
    : "ws://localhost:3000/cable"; // development 환경에서는 기본 URL 사용 (ws://localhost:3000/cable)

export default createConsumer(wsUrl);
