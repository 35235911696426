import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
initialize() {
    this.sortableInstance = null
}
  connect() {
    console.log("DragDropToggle controller connected");
    this.element.checked = false;
    this.updateDragDropState();
  }

  updateDragDropState() {
    console.log("updateDragDropState called");
    const isEnabled = this.element.checked;
    console.log("Checkbox state:", isEnabled);

    const allBannerElements = document.querySelectorAll("turbo-frame[id^='banner-slot-']");
    console.log("Found banner elements:", allBannerElements.length);

    allBannerElements.forEach((element) => {
      const isFixed = element.dataset.fixed === 'true';
      console.log(`Banner ${element.id} - Fixed: ${isFixed}`);

      if (isEnabled && !isFixed) {
        console.log(`Making banner ${element.id} movable`);
        element.classList.remove("banner-immovable");
        element.classList.add("banner-movable");
        element.style.cursor = "move";
      } else {
        console.log(`Making banner ${element.id} immovable`);
        element.classList.remove("banner-movable");
        element.classList.add("banner-immovable");
        element.style.cursor = "not-allowed";
      }
    });

    // Sortable.js initialization and state management
    const sortableContainer = document.getElementById("sortable-banners")
    if (sortableContainer) {
    if (isEnabled) {
        if (!this.sortableInstance) {
        try {
            this.sortableInstance = new Sortable(sortableContainer, {
            animation: 150,
            handle: ".banner-movable",
            draggable: ".banner-movable",
            onStart: (evt) => {
                evt.item.classList.add("dragging")
            },
            onEnd: (evt) => {
                evt.item.classList.remove("dragging")
                const itemId = evt.item.getAttribute('data-banner-id')
                const newIndex = evt.newIndex
                
                // Update position via AJAX
                fetch(`/admin/banners/${itemId}/reorder`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({ position: newIndex + 1 })
                })
                .then(response => {
                if (!response.ok) throw new Error('Network response was not ok')
                return response.json()
                })
                .then(data => {
                console.log('Position updated successfully:', data)
                })
                .catch(error => {
                console.error('Error updating position:', error)
                // Revert the change on error
                sortableContainer.insertBefore(evt.item, sortableContainer.children[evt.oldIndex])
                })
            }
            })
            console.log("Sortable initialized and enabled")
        } catch (error) {
            console.error("Failed to initialize Sortable:", error)
        }
        }
    } else {
        if (this.sortableInstance) {
        this.sortableInstance.destroy()
        this.sortableInstance = null
        console.log("Sortable destroyed")
        }
    }
    } else {
    console.error("Sortable container not found")
    }
}

disconnect() {
    if (this.sortableInstance) {
    this.sortableInstance.destroy()
    this.sortableInstance = null
    console.log("Sortable cleanup on disconnect")
    }
}
}
